<template lang="">
  <span>
    <div class="p-fluid formgrid grid">
      <div class="field col-4 md:col-4">
        <label for="periodo">Periodo</label>
        <Calendar
          id="periodo"
          v-model="v$.periodo.$model"
          selectionMode="multiple"
          :class="{
            'p-invalid': submitted && v$.periodo.$invalid,
          }"
          view="month"
          dateFormat="mm/yy"
          :yearNavigator="true"
          @input="adicionarPeriodo" />
        <div v-if="submitted && v$.periodo.required.$invalid" class="p-error">
          O campo Periodo é obrigatório.
        </div>
      </div>
      <div class="field col-4 md:col-4">
        <label for="entidade">Entidade</label>
        <MultiSelect
          id="entidade"
          v-model="v$.entidadesSelecionadas.$model"
          dataKey="id"
          optionLabel="nome"
          :options="entidades"
          :filter="true"
          filterPlaceholder="Procure pelo nome da entidade"
          :emptyFilterMessage="'Nenhuma entidade encontrada'"
          placeholder="Selecione uma entidade"
          display="chip"
          @input="adicionarEntidade">
        </MultiSelect>
        <div
          v-if="submitted && v$.entidadesSelecionadas.required.$invalid"
          class="p-error">
          O campo entidade é obrigatório.
        </div>
      </div>
      <div v-if="exibirListaConsignataria" class="field col-4 md:col-4">
        <label for="consignataria">Consignatária</label>
        <MultiSelect
          id="consignataria"
          v-model="v$.consignatariasSelecionadas.$model"
          optionLabel="cnpjRazaoSocial"
          :options="concatenarCnpjRazaoSocial"
          dataKey="id"
          :filter="true"
          filterPlaceholder="Procure pelo nome da consignataria"
          :emptyFilterMessage="'Nenhuma consignataria encontrada'"
          placeholder="Selecione uma consignataria"
          display="chip"
          @input="adicionarConsignataria">
        </MultiSelect>
        <div
          v-if="submitted && v$.consignatariasSelecionadas.required.$invalid"
          class="p-error">
          O campo consignataria é obrigatório.
        </div>
      </div>
    </div>
  </span>
</template>
<script>
import UseVuelidate from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
import ConsignatariaService from '@/service/ConsignatariaService.js'
import EntidadeService from '@/service/EntidadeService.js'

export default {
  name: 'FiltroRelatorioCobranca',

  props: {
    submitted: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      periodo: '',
      entidades: [],
      entidadesSelecionadas: [],
      consignatarias: [],
      consignatariasSelecionadas: [],
      exibirListaConsignataria: false,
      parametros: new Map(),
    }
  },

  computed: {
    concatenarCnpjRazaoSocial() {
      return this.consignatarias.map((consignataria) => ({
        ...consignataria,
        cnpjRazaoSocial: consignataria.cnpj + ' | ' + consignataria.razaoSocial,
      }))
    },
  },

  validations() {
    return {
      periodo: { required },
      consignatariasSelecionadas: {
        required: requiredIf(
          this.$auth.hasRoleGestao() ||
            this.$auth.hasRoleGestaoEntidade() ||
            this.$auth.hasRoleGestaoFinanceira()
            ? true
            : false,
        ),
      },
      entidadesSelecionadas: { required },
    }
  },

  created() {
    this.consignatariaService = new ConsignatariaService(this.$http)
    this.entidadeService = new EntidadeService(this.$http)
  },

  mounted() {
    this.carregarEntidade()
    this.carregarConsignataria()
  },

  methods: {
    carregarEntidade() {
      this.entidadeService.getListaEntidades().then((res) => {
        this.entidades = res.filter((entidade) => entidade.ativo)
      })
    },

    carregarConsignataria() {
      if (
        this.$auth.hasRoleGestao() ||
        this.$auth.hasRoleGestaoEntidade() ||
        this.$auth.hasRoleGestaoFinanceira()
      ) {
        this.carregarListaConsignatarias()
        this.exibirListaConsignataria = true
      } else {
        this.consignatariasSelecionadas.push(
          JSON.parse(localStorage.getItem('consignatariaSelecionada')),
        )
        this.adicionarConsignataria()
        this.exibirListaConsignataria = false
      }
    },

    carregarListaConsignatarias() {
      if (this.$auth.hasRoleGestao() || this.$auth.hasRoleGestaoFinanceira()) {
        this.consignatariaService.getConsignatarias().then((res) => {
          this.consignatarias = res.filter(
            (consignataria) => consignataria.ativo,
          )
        })
      } else if (this.$auth.hasRoleGestaoEntidade()) {
        this.consignatariaService
          .getConsignatariasUsuarioGestaoEntidade()
          .then((res) => {
            this.consignatarias = res.filter(
              (consignataria) => consignataria.ativo,
            )
          })
      }
    },

    validate() {
      this.v$.periodo.$touch()
      this.v$.consignatariasSelecionadas.$touch()
      this.v$.entidadesSelecionadas.$touch()

      return this.v$.periodo.$invalid ||
        this.v$.consignatariasSelecionadas.$invalid ||
        this.v$.entidadesSelecionadas.$invalid
        ? true
        : false
    },

    adicionarPeriodo() {
      if (
        this.periodo.length > 1 &&
        this.parametros.get('periodo').length < this.periodo.length
      ) {
        const ultimoElemento = this.periodo.pop()
        this.periodo.unshift(ultimoElemento)
      }
      this.parametros.set('periodo', this.periodo)
      this.$emit('parametros', this.parametros)
    },

    adicionarEntidade() {
      this.parametros.set(
        'entidades',
        this.entidadesSelecionadas.map((entidade) => entidade.id.toString()),
      )

      this.$emit('parametros', this.parametros)
    },

    adicionarConsignataria() {
      this.parametros.set(
        'consignatarias',
        this.consignatariasSelecionadas.map((consignataria) =>
          consignataria.id.toString(),
        ),
      )

      this.$emit('parametros', this.parametros)
    },
    limpar() {
      this.entidadesSelecionadas = []
      this.consignatariasSelecionadas = []
      this.periodo = ''
    },
  },
}
</script>
<style lang=""></style>
