export default class StatusReservaDeCartaoService {
  constructor(http) {
    this._http = http
  }

  async getStatusReservaDeCartao() {
    const { data } = await this._http.get(`/api/status-reserva-cartao`)
    return data
  }
}
