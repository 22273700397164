<template>
  <div>
    <div class="p-fluid formgrid grid">
      <div class="field col-6 md:col-6">
        <label for="dataEntidade">Data de Referência</label>
        <Calendar
          v-model="v$.dataReferencia.$model"
          :class="{
            'p-invalid': submitted && v$.dataReferencia.$invalid,
          }"
          view="month"
          dateFormat="mm/yy"
          @input="adicionarMesEAnoReferencia" />
        <div
          v-if="submitted && v$.dataReferencia.required.$invalid"
          class="p-error">
          O campo Data de Referência é obrigatório.
        </div>
      </div>
      <div class="field col-6 md:col-6">
        <label for="responsavel">Responsável</label>
        <MultiSelect
          id="responsavel"
          v-model="v$.responsaveisSelecionados.$model"
          data-key="responsavel"
          :class="{
            'p-invalid': submitted && v$.responsaveisSelecionados.$invalid,
          }"
          :options="responsaveis"
          optionLabel="responsavel"
          optionValue="responsavel"
          :filter="true"
          filterPlaceholder="Procure pelo nome do responsável"
          :emptyFilterMessage="'Nenhum responsável encontrado'"
          placeholder="Selecione um responsável"
          display="chip"
          @input="adicionarResponsavel" />
        <div
          v-if="submitted && v$.responsaveisSelecionados.required.$invalid"
          class="p-error">
          O campo responsável é obrigatório.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import RelatorioService from '@/service/RelatorioService'

export default {
  name: 'LayoutFiltroTaxaAssistencial',

  props: {
    submitted: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      dataReferencia: null,
      anoReferencia: null,
      mesReferencia: null,
      responsaveis: [],
      responsaveisSelecionados: [],
      parametros: new Map(),
    }
  },

  validations() {
    return {
      dataReferencia: { required },
      responsaveisSelecionados: { required },
    }
  },

  created() {
    this.responsavelService = new RelatorioService(this.$http)
  },

  mounted() {
    this.carregarResponsaveis()
  },

  methods: {
    carregarResponsaveis() {
      this.responsavelService.getListaResponsaveis().then((res) => {
        this.responsaveis = res
      })
    },

    validate() {
      this.v$.dataReferencia.$touch()
      this.v$.responsaveisSelecionados.$touch()

      return this.v$.dataReferencia.$invalid ||
        this.v$.responsaveisSelecionados.$invalid
        ? true
        : false
    },

    adicionarMesEAnoReferencia() {
      this.mesReferencia = (this.dataReferencia.getMonth() + 1).toString()
      this.anoReferencia = `${this.dataReferencia.getFullYear()}`
      this.parametros.set('mesReferencia', this.mesReferencia)
      this.parametros.set('anoReferencia', this.anoReferencia)
      this.$emit('parametros', this.parametros)
    },

    adicionarResponsavel() {
      this.parametros.set('responsaveis', this.responsaveisSelecionados)
      this.$emit('parametros', this.parametros)
    },

    limpar() {
      this.dataReferencia = null
      this.anoReferencia = null
      this.mesReferencia = null
      this.responsaveisSelecionados = []
    },
  },
}
</script>
