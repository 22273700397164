<template>
  <span>
    <div class="p-fluid formgrid grid">
      <div class="field col-6 md:col-6">
        <label for="dataEntidade">Data de Referência</label>
        <Calendar
          v-model="v$.dataReferencia.$model"
          :class="{
            'p-invalid': submitted && v$.dataReferencia.$invalid,
          }"
          view="month"
          dateFormat="mm/yy"
          @input="adicionarMesEAnoReferencia" />
        <div
          v-if="submitted && v$.dataReferencia.required.$invalid"
          class="p-error">
          O campo Data de Referência é obrigatório.
        </div>
      </div>
      <div class="field col-6 md:col-6">
        <label for="cpfServidor">
          {{ modulo === 'servidor' ? 'CPF do Servidor' : 'CPF ou Matricula' }}
        </label>
        <InputText
          v-model="cpfMatricula"
          type="text"
          placeholder="99999999999"
          :disabled="modulo === 'servidor'"
          @input="adicionarCpfMatriculaServidor" />
      </div>
    </div>
    <div v-show="exibirListaEntidades" class="p-fluid formgrid grid">
      <div class="field col-6 md:col-6">
        <label for="entidade">Entidade</label>
        <MultiSelect
          id="entidade"
          v-model="v$.entidadesSelecionadas.$model"
          :class="{
            'p-invalid': submitted && v$.entidadesSelecionadas.$invalid,
          }"
          optionLabel="nome"
          :options="entidades"
          :filter="true"
          filterPlaceholder="Procure pelo nome da entidade"
          :emptyFilterMessage="'Nenhuma entidade encontrada'"
          placeholder="Selecione uma entidade"
          display="chip"
          @input="adicionarEntidade">
        </MultiSelect>
        <div
          v-if="submitted && v$.entidadesSelecionadas.required.$invalid"
          class="p-error">
          O campo Entidade é obrigatório.
        </div>
      </div>
      <div v-show="exibirListaConsignataria" class="field col-6 md:col-6">
        <label for="consignataria">Consignatária</label>
        <MultiSelect
          id="consignataria"
          v-model="v$.consignatariasSelecionadas.$model"
          :class="{
            'p-invalid': submitted && v$.consignatariasSelecionadas.$invalid,
          }"
          dataKey="id"
          :disabled="habilitarCampoConsignataria"
          optionLabel="cnpjRazaoSocial"
          :options="concatenarCnpjRazaoSocial"
          :filter="true"
          filterPlaceholder="Procure pelo nome da consignataria"
          :emptyFilterMessage="'Nenhuma consignataria encontrada'"
          placeholder="Selecione uma consignataria"
          display="chip"
          @input="adicionarConsignataria">
        </MultiSelect>
        <div
          v-if="submitted && v$.consignatariasSelecionadas.required.$invalid"
          class="p-error">
          O campo Consignataria é obrigatório.
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import ConsignatariaService from '@/service/ConsignatariaService.js'
import EntidadeService from '@/service/EntidadeService.js'
import { consignatariaStore } from '@/stores/consignataria'
import ServidorService from '@/service/ServidorService'

export default {
  name: 'LayoutFiltro05',

  props: {
    submitted: {
      type: Boolean,
      default: false,
    },
    modulo: {
      type: String,
      required: true,
    },
  },

  setup() {
    const store = consignatariaStore()
    return { v$: UseVuelidate(), store }
  },

  data() {
    return {
      entidades: [],
      entidadesSelecionadas: [],
      entidadesIdSelecionados: [],
      consignatarias: [],
      consignatariasSelecionadas: [],
      consignatariasIdSelecionados: [],
      cpfMatricula: null,
      dataReferencia: null,
      mesReferencia: null,
      anoReferencia: null,
      exibirListaEntidades: false,
      exibirListaConsignataria: false,
      parametros: new Map(),
    }
  },

  computed: {
    habilitarCampoConsignataria() {
      return this.store.consignatariaSelecionada !== null
    },

    concatenarCnpjRazaoSocial() {
      return this.consignatarias.map((consignataria) => ({
        ...consignataria,
        cnpjRazaoSocial: consignataria.cnpj + ' | ' + consignataria.razaoSocial,
      }))
    },
  },

  validations() {
    return {
      dataReferencia: { required },
      entidadesSelecionadas: { required },
      consignatariasSelecionadas: { required },
    }
  },

  created() {
    this.consignatariaService = new ConsignatariaService(this.$http)
    this.entidadeService = new EntidadeService(this.$http)
    this.servidorService = new ServidorService(this.$http)
    this.adicionarConsignatariaPreviamente()
  },

  mounted() {
    this.carregarEntidade()
    this.carregarConsignataria()
    this.carregarMatriculaCpf()
  },

  methods: {
    carregarEntidade() {
      this.carregarListaEntidades()
      this.exibirListaEntidades = true
    },

    carregarConsignataria() {
      this.carregarListaConsignatarias()
      this.exibirListaConsignataria = true
    },

    carregarMatriculaCpf() {
      this.servidorService.getDadosServidor().then((res) => {
        if (this.modulo === 'servidor') {
          this.cpfMatricula = res.cpf
          this.adicionarCpfMatriculaServidor()
        }
      })
    },

    validate() {
      this.v$.dataReferencia.$touch()
      this.v$.entidadesSelecionadas.$touch()
      this.v$.consignatariasSelecionadas.$touch()

      return (
        this.v$.dataReferencia.$invalid ||
        this.v$.entidadesSelecionadas.$invalid ||
        this.v$.consignatariasSelecionadas.$invalid
      )
    },

    adicionarEntidade() {
      this.adiconarListaIds('entidade')
      this.parametros.set('entidadeId', this.entidadesIdSelecionados)
      this.$emit('parametros', this.parametros)
      this.entidadesIdSelecionados = []
    },

    adicionarConsignataria() {
      this.adiconarListaIds('consignataria')
      this.parametros.set('consignatariaId', this.consignatariasIdSelecionados)
      this.$emit('parametros', this.parametros)
      this.consignatariasIdSelecionados = []
    },

    adiconarListaIds(tipo) {
      if (tipo == 'entidade') {
        this.entidadesSelecionadas.forEach((valor) =>
          this.entidadesIdSelecionados.push(valor.id.toString()),
        )
      } else if (tipo == 'consignataria') {
        this.consignatariasSelecionadas.forEach((valor) =>
          this.consignatariasIdSelecionados.push(valor.id.toString()),
        )
      }
    },

    adicionarCpfMatriculaServidor() {
      this.parametros.set('cpfMatricula', this.cpfMatricula)
      this.$emit('parametros', this.parametros)
    },

    adicionarMesEAnoReferencia() {
      this.mesReferencia = (this.dataReferencia.getMonth() + 1).toString()
      this.anoReferencia = `${this.dataReferencia.getFullYear()}`
      this.parametros.set('mesReferencia', this.mesReferencia)
      this.parametros.set('anoReferencia', this.anoReferencia)
      this.$emit('parametros', this.parametros)
    },

    limpar() {
      this.entidadesSelecionadas = []
      this.entidadesIdSelecionados = []
      this.consignatariasSelecionadas = []
      this.consignatariasIdSelecionados = []
      if (this.modulo !== 'servidor') {
        this.cpfMatricula = ''
      }
      this.dataReferencia = ''
      this.mesReferencia = ''
      this.anoReferencia = ''
    },

    adicionarConsignatariaPreviamente() {
      if (
        this.store.consignatariaSelecionada &&
        !this.parametros.get('consignatariaId')
      ) {
        this.parametros.set(
          'consignatariaId',
          this.store.consignatariaSelecionada.id.toString(),
        )
        this.$emit('parametros', this.parametros)
      }
    },
  },
}
</script>
