export default class StatusConsignacaoService {
  constructor(http) {
    this._http = http
  }

  async getListaStatus() {
    const { data } = await this._http.get(`/api/status-consignacao/listaStatus`)
    return data
  }
}
